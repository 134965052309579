import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

import {getCurrentEntity, setLocationHistory} from "../../slices/SampleSlice";
import {getCurrentUser, getUser} from "../../slices/UserSlice";
import ErrorBoundary from "../../utilities/boundaries/errorBoundary/ErrorBoundary";
import ErrorBoundaryForQRContainer from "../../utilities/boundaries/errorBoundary/ErrorBoundaryForQRContainer";
import RequireAuth from "../../utilities/boundaries/RequireAuth";
import KitActionBase from "../ActionDependentComponents/KitAction/KitActionBase";
import SampleActionBase from "../ActionDependentComponents/SampleAction/SampleActionBase";
import SkuListBase from "../ActionDependentComponents/SkuList/SkuListBase";
import UserActions from "../actions/UserActions";
import AppLogo from "../App/AppLogo";
import ReturnForm from "../BrandManager-return/ReturnForm";
import NavigationPanel from "../Navigation/NavigationPanel";
import QRReaderContainer from "../QRReader/QRReaderContainer";
import SwipeableEdgeDrawer from "../SamplesImages/CameraDrawer";
import Scaner from "../SamplesImages/Scaner";
import SkuListPage from "../SkuList.js/SkuListPage";

import SamplesInfo from "./SamplesInfo";

const useNavigationHistory = () => {
  const [history, setHistory] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setHistory(prevHistory => {
      const newHistory = [...prevHistory];
      if (!newHistory.includes(location.pathname)) {
        // Добавляем новый путь в начало истории, если его нет в списке
        newHistory.unshift(location.pathname);
      }
      return newHistory.slice(0, 2); // Ограничиваем историю двумя последними путями
    });
  }, [location]);

  useEffect(() => {
    dispatch(setLocationHistory(history));
  }, [history]);

  return {history};
};

const IndexPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const token = useSelector(state => state.user.token);
  const current = useSelector(getCurrentEntity);

  const history = useNavigationHistory();

  useEffect(() => {
    dispatch(getUser());
  }, [token]);

  return (
    <>
      <NavigationPanel>
        <AppLogo />
      </NavigationPanel>
      <Routes>
        <Route
          path="/userActionType"
          element={
            <RequireAuth>
              <UserActions />
            </RequireAuth>
          }
        />

        {/* <Route
          path="/actions/skuList/*"
          element={
            <ErrorBoundary>
              <SkuListBase />
            </ErrorBoundary>
          }
        /> */}
        <Route
          path="/actions/sample/*"
          element={
            <ErrorBoundary>
              <SampleActionBase />
            </ErrorBoundary>
          }
        />
        <Route
          path="/actions/kit/*"
          element={
            <ErrorBoundary>
              <KitActionBase />
            </ErrorBoundary>
          }
        />
        <Route
          path="/sample"
          element={
            <RequireAuth>
              <SamplesInfo />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/qrread/*"
          element={
            <RequireAuth>
              <ErrorBoundaryForQRContainer>
                {/* <QRcontainer /> */}
                <QRReaderContainer />
              </ErrorBoundaryForQRContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/sku-list"
          element={
            <RequireAuth>
              <SkuListPage />
            </RequireAuth>
          }
        />
        <Route
          path="/return-sample"
          element={
            <RequireAuth>
              <ReturnForm />
            </RequireAuth>
          }
        />
        <Route
          path="/add-samples-photo"
          element={
            <ErrorBoundary>
              <SwipeableEdgeDrawer />
            </ErrorBoundary>
          }
        />

        <Route
          path="/samples-image"
          // element={
          //   <ErrorBoundaryForQRContainer>
          //     <Scaner />
          //   </ErrorBoundaryForQRContainer>
          // }
          element={
            current && current?.brandManagerId === user?.userId ? (
              <ErrorBoundaryForQRContainer>
                <Navigate to="/ba3a/samples/add-samples-photo" />
              </ErrorBoundaryForQRContainer>
            ) : (
              <ErrorBoundaryForQRContainer>
                <Scaner />
              </ErrorBoundaryForQRContainer>
            )
          }
        />
      </Routes>
    </>
  );
};

export default IndexPage;
